import { BLANK_PDF, Form, Viewer } from "@pdfme/ui";

export const displayFormAsPdf = async (form: any) => {
    // console.log(form)
    const domContainer = document.getElementById("pdf-container");
    let numberedSchemas: any = {}
    let numberedInputs: any = {}
    let schema: any = {}
    let input: any = {}
    form.forEach((page: any, pageIndex: number) => {
        page.formElements.forEach((formElement: any, formElementIndex: number) => {
            if (!(formElement.pdfPageNumber in numberedSchemas)) {
                numberedSchemas[formElement.pdfPageNumber] = {}
                numberedInputs[formElement.pdfPageNumber] = {}
            }
            if (formElement.hasOwnProperty("value")) {
                const name = `page${pageIndex.toString()}-formElement${formElementIndex.toString()}`
                if (formElement.type === "radio") {
                    const schemaElement = {
                        type: "text",
                        fontSize: 12,
                        fontName: "Roboto",
                        position: formElement.value.position,
                        width: 5,
                        height: 5,
                    }
                    numberedSchemas[formElement.pdfPageNumber][name] = schemaElement
                    numberedInputs[formElement.pdfPageNumber][name] = "x"
                }
                // } else if (formElement.type === "text-field") {
                //     schema[name].position = formElement.position
                //     schema[name].width = 5
                //     schema[name].height = 5
                //     input[name] = formElement.value
                // }
            }
        })
    })
    const template = {
        "schemas": Object.values(numberedSchemas),
        "basePdf": await urlContentToDataUri("pdfs/QSS-new.pdf")
    }
    const inputs = Object.values(numberedInputs)

    // console.log('Object.values(numberedSchemas)')
    // console.log(Object.values(numberedSchemas))
    // console.log('Object.values(numberedInputs)')
    // console.log(Object.values(numberedInputs))

    // const formPdf = new Form({ domContainer, template, inputs });
    const viewer = new Viewer({ domContainer, template, inputs });
}

export const displayPdfWithFormTemplate = async (pdfPath: string, formTemplatePath: string) => {

    const domContainer = document.getElementById('container');
    const template = {
    "schemas": [
        {
        "name": {
            "type": "text",
            "position": {"x": 24.62, "y": 24.15},
            "width": 43.38,
            "height": 6.12,
            "fontSize": 12,
            "fontName": "Roboto"
        },
        "firstName": {
            "type": "text",
            "position": {
            "x": 24.36,
            "y": 30.39
            },
            "width": 43.38,
            "height": 6.12,
            "fontSize": 12,
            "fontName": "Roboto"
        },
        "address": {
            "type": "text",
            "position": {
            "x": 22.8,
            "y": 36.36
            },
            "width": 43.38,
            "height": 6.12,
            "fontSize": 12,
            "fontName": "Roboto"
        },
        "city": {
            "type": "text",
            "position": {
            "x": 19.1,
            "y": 41.93
            },
            "width": 43.38,
            "height": 6.12,
            "fontSize": 12,
            "fontName": "Roboto"
        },
        "job": {
            "type": "text",
            "position": {
            "x": 41.27,
            "y": 103.72
            },
            "width": 136.07,
            "height": 7,
            "alignment": "left",
            "fontSize": 13,
            "characterSpacing": 0,
            "lineHeight": 1,
            "fontName": "Roboto"
        }
        },
        {}
    ],
    "basePdf": await urlContentToDataUri("pdfs/QSS-new.pdf")
    };
    const inputs = [
        {
            "name": "Test",
            "firstName": "Test",
            "address": "12 rue des tests",
            "city": "Lille",
            "job": "Allumeur de lampadaire"
        }
    ];

    // const form = new Form({ domContainer, template, inputs });
    const viewer = new Viewer({ domContainer, template, inputs });
}

export const urlContentToDataUri = async (url: string) => {
    return fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise(callback => {
            let reader = new FileReader()
            reader.onload = function(){callback(this.result)}
            reader.readAsDataURL(blob)
        }))
}