import { customElement } from "lit/decorators.js";
import { View } from "../view";
import { html } from "lit";

@customElement("document-end")
export class DocumentEndView extends View {

    render(){
        return html`
            <div class="cdui-document-end">
                <h3 style="width:100%">Documents transmis</h3>
                <p>Vos documents ont bien été transmis, ils seront traités dans les plus bref délais.</p>
                <p>Si l'ensemble des documents demandés n'ont pas été communiqués, vous pourrez vous reconnecter dans quelques minutes pour transmettre les documents manquants.</p>
            </div>
        `;
    }

}