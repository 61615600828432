import { Upload, UploadFilesChangedEvent, UploadResponseEvent } from "@vaadin/upload";
import RequiredDocument from "Frontend/generated/oxynoia/documents/collection/b2b/model/RequiredDocument";
import { frenchUploadI18n } from "Frontend/utils/french-i18n";
import { LitElement, PropertyValueMap, css, html, nothing } from "lit";
import { customElement, property, query, queryAsync, state } from "lit/decorators.js";
import { DocumentPreview, DocumentType } from "./document-preview";
import { DocumentEndpoint, FileUploadEndpoint } from "Frontend/generated/endpoints";
import DocumentStatus from "Frontend/generated/oxynoia/documents/collection/b2c/model/DocumentStatus";
import { applyTheme } from "Frontend/generated/theme";
import Document from "Frontend/generated/oxynoia/documents/collection/b2c/model/Document";

const maxFiles = 1;
const maxFileSizeInMB = 10;
const maxFileSizeInBytes = maxFileSizeInMB * 1024 * 1024;

@customElement("document-upload")
export class DocumentUpload extends LitElement {

    @property()
    private transactionId!: string;

    @property()
    private collectId!: string;

    @state()
    document!: RequiredDocument;

    @state()
    private _statusText: string = "";

    @state()
    private _statusClasses: string = "state-pending-txt";

    @state()
    private _delCmdClasses: string = "delbutton-container";

    @state()
    private _displayCat: boolean = false;

    @state()
    private _displayProgress: boolean = false;

    @queryAsync("document-preview")
    private _previewElement!: Promise<DocumentPreview>;

    @query("vaadin-upload")
    private _upload!: Upload;

    @state()
    private _uploadDisabled: boolean = false;

    @state()
    private _displayPreview: boolean = true;

    @state()
    private _displayDelCmd: boolean = false;

    @state()
    private _sendFilesDisabled: boolean = true;

    @state()
    private _displayStatus: boolean = false;

    private _currentInterval!: NodeJS.Timeout | undefined;

    private files: Document[] = [];


    render() {
        return html`
            <div>
                <span id="askedcat" ?hidden=${!this._displayCat} >${this.document?.category}</span>
                <div id="progress" ?hidden=${!this._displayProgress}>
                    <vaadin-progress-bar indeterminate></vaadin-progress-bar>
                </div>
                <vaadin-upload
                        capture="environment"
                        .i18n=${frenchUploadI18n}
                        .target="${"file/upload/" + this.document?.category}"
                        .maxFileSize="${maxFileSizeInBytes}"
                        no-auto
                        accept="application/pdf,.pdf,image/jpeg,.jpeg,.jpg,image/png,.png,.jpg,image/tiff,.tiff,.tif"
                        @upload-response=${this.handleUpload}
                        @files-changed=${(e: UploadFilesChangedEvent) => this._sendFilesDisabled = e.detail.value.length == 0}
                        @file-abort=${(e: UploadResponseEvent) => {
                            if(e.detail.file.status == ''){
                                this.handleFileUploadAbort();
                            }
                        }}
                >
                    <div slot="add-button">
                        <document-preview class="previewCls" ?hidden=${!this._displayPreview} >
                        </document-preview>
                        <span ?hidden=${!this._displayStatus} class=${this._statusClasses}>${this._statusText}</span>
                        <div class="upload-buttons">
                            <vaadin-button theme="icon" class="upload-button" .hidden=${this._uploadDisabled}><vaadin-icon icon="vaadin:plus"></vaadin-icon></vaadin-button>
                        </div>
                    </div>
                    <vaadin-upload-file-list slot="file-list" .hidden=${this._uploadDisabled}></vaadin-upload-file-list>
                    <div slot="drop-label" style="display:inline">
                        <span>Glissez-ici</span>
                        <vaadin-button class="upload-button" style="display:flex" .disabled=${this._sendFilesDisabled} .hidden=${this._uploadDisabled} @click=${() => this._upload.uploadFiles()}>Envoyer</vaadin-button>
                    </div>
                    <div>
                        <span id="delcmd" 
                            class="${this._displayDelCmd ? this._delCmdClasses : "hidden"}">
                            <cdui-button 
                                class="cdui-del-document" 
                                @click=${() => this.handleFileUploadAbort()}> <i class="fa fa-trash" aria-hidden="true"></i>
                            </cdui-button>
                        </span>
                    </div>
                </vaadin-upload>
            </div>
        `
    }

    async handleUpload(e: UploadResponseEvent){
        if (e.detail.xhr.status == 200) {
            var response = JSON.parse(e.detail.xhr.response) as Document;
            if (this.files.length == 0) {
                //upload of first page is done, storing it for preview
                if (this._previewElement !== undefined) {
                    var reader = new FileReader();
                    reader.readAsDataURL(e.detail.file);
                    let parent: DocumentUpload = this;
                    reader.onloadend = function () {
                        var base64data = reader.result as string;
                        if (e.detail.file.type.match('application/pdf')) {
                            parent._previewElement.then(previewElement => {
                                previewElement.data = base64data.substring(base64data.indexOf(',') + 1);
                                previewElement.documentType = DocumentType.PDF;
                            })
    
                        } else {
                            parent._previewElement.then(previewElement => {
                                previewElement.data = base64data;
                                previewElement.documentType = DocumentType.IMAGE;
                            })
                        }
                    }
                }
            }
            if (response.id != undefined) {
                this.files.push(response);
            }
            if (this.files.length == this._upload.files.length) {
                //call check
                this._statusText = 'Votre document est en cours de vérification...';
                this._uploadDisabled = true;
                this._upload.maxFiles = 0;
                this._displayProgress = true;
                this._displayPreview = false;
                FileUploadEndpoint.mergeFilesAndStartCheck(this.document?.category, this.collectId, this.transactionId, this.files.map(file => file.id as number))
                .then(resp => {
                    var transactionIdWithSuffix = resp as string;
                    this._currentInterval = setInterval(this.checkResponse, 1200, this, transactionIdWithSuffix);
                    setTimeout(this.responseTimout, 1800000, this, this.transactionId, this._currentInterval);
                })
            }
        }
    }

    // async handleResponse(e: UploadResponseEvent, transactionId: string, suffix: string) {
    //     if (e.detail.xhr.status == 200) {
    //         this._statusText = 'Votre document est en cours de vérification...';
    //         if (this._previewElement !== undefined) {
    //             var reader = new FileReader();
    //             reader.readAsDataURL(e.detail.file);
    //             let parent: DocumentUpload = this;
    //             reader.onloadend = function () {
    //                 var base64data = reader.result as string;
    //                 if (e.detail.file.type.match('application/pdf')) {
    //                     parent._previewElement.then(previewElement => {
    //                         previewElement.data = base64data.substring(base64data.indexOf(',') + 1);
    //                         previewElement.documentType = DocumentType.PDF;
    //                     })

    //                 } else {
    //                     parent._previewElement.then(previewElement => {
    //                         previewElement.data = base64data;
    //                         previewElement.documentType = DocumentType.IMAGE;
    //                     })
    //                 }
    //             }
    //         }
    //         var transactionIdWithSuffix = e.detail.xhr.responseText;
    //         this._uploadDisabled = true;
    //         this._displayProgress = true;
    //         this._displayPreview = false;
    //         this._currentInterval = setInterval(this.checkResponse, 1200, this, transactionIdWithSuffix);
    //         setTimeout(this.responseTimout, 1800000, this, suffix, this._currentInterval);
    //     } else {
    //         //error management
    //         // Notification.show("Oops, something went wrong.")
    //     }
    // }

    checkResponse(parent: DocumentUpload, transactionId: string) {
        FileUploadEndpoint.fileHasResponse(transactionId).then((hasResponse: boolean) => {
            if (hasResponse) {
                FileUploadEndpoint.getFileResponse(transactionId).then((match: DocumentStatus | undefined) => {
                    // matchCat = match;
                    clearInterval(parent._currentInterval);
                    parent._currentInterval = undefined;
                    parent._displayProgress = false;
                    console.log("Return of the call is : " + match);
                    switch (match) {
                        case DocumentStatus.MATCH:
                            parent._statusText = '';
                            parent.validateStatus();
                            // parent.querySelector("#signature-" + suffix)?.removeAttribute('disabled');
                            parent._displayPreview = true;
                            parent._previewElement.then(previewElement => previewElement.isValid = true);
                            parent.restoreDocumentsState();
                            break;
                        case DocumentStatus.UNRECOGNIZED_DOCUMENT:
                            parent._displayPreview = false;
                            parent._statusText = "Le document n'a pas pu être identifié. Il est préférable d'en renvoyer un.";
                            break;
                        default:
                            parent._statusText = "Document bien identifié, mais non recevable en tant que " + parent.document.category;
                            break;
                    }
                    parent._displayDelCmd = true;
                })
            }
        })
    }

    responseTimout(parent: DocumentUpload, suffix: string, intervalId: NodeJS.Timeout) {
        clearInterval(intervalId);
        parent._displayProgress = false;
        parent._statusText = "Une erreur est survenue lors du traitement de votre document, veuillez réessayer svp.";
    }

    validateStatus() {
        this.dispatchEvent(new CustomEvent('upload-ok', {
            detail: {
                isValid: true
            }
        }) as UploadOkEvent);
    }

    restoreDocumentsState() {
        this.dispatchEvent(new CustomEvent('doc-state', {}) as FlushDocumentsStateEvent)
    }

    handleFileUploadAbort() {
        FileUploadEndpoint.cancelFile(this.collectId, this.transactionId).then(rsp => {
            this.toUpload();
            this._uploadDisabled = false;
            this._displayProgress = false;
            this._previewElement.then(previewElement => {
                previewElement.data = "";
                previewElement.documentType = DocumentType.NONE;
                this._displayPreview = true;
            });
            this._displayDelCmd = false;
            this.files = [];

            console.log(rsp);
            this._upload.files= [];
            this._upload.maxFiles = -1;
            this.restoreDocumentsState();
        });
    }

    setPreview(data: string, docType: DocumentType) {
        this._previewElement.then(previewElement => {
            previewElement.data = data;
            previewElement.documentType = docType;
        });

    }

    setPreviewValid(isValid: boolean) {
        this._previewElement.then(previewElement => {
            previewElement.isValid = isValid;
        });

    }

    alreadyUploaded() {
        this._upload.hidden = true;
        this._upload.setAttribute("display", "none");
        this._statusClasses = "already-submit-txt";
        this._statusText = "Déjà soumis";
        this._displayDelCmd = false;
    }

    toUpload() {
        this._statusClasses = "state-pending-txt";
        this._statusText = "A remplir";
    }

    pending() {
        this._statusText = "";
        this._upload.hidden = false;
        this._displayDelCmd = true;
        this._statusClasses = "state-pending-txt";
        this._statusText = "En attente";
    }

    setStatus(status: string) {
        this._statusText = status;
    }

    setDisplayPreview(displayPreview: boolean) {
        this._displayPreview = displayPreview;
    }

    setDisplayUpload(displayUpload: boolean) {
        this._upload.hidden = !displayUpload;
    }

    setDisplayDelCmd(displayDelCmd: boolean) {
        this._displayDelCmd = displayDelCmd;
    }

    connectedCallback(): void {
        super.connectedCallback();
        applyTheme(this.renderRoot);
    }

    protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        if(_changedProperties.has("document")){
            if (this.document !== undefined && this.document.uploadedDocument !== undefined) {
                if (this.document.uploadedDocument.data == undefined) {
                    DocumentEndpoint.getDocument(this.document.uploadedDocument.documentId).then(response => {
                        console.log("Data for " + this.document.id + " : " + response?.data?.substring(0, 20));
    
                        this._previewElement.then(previewElement => {
                            if (response?.data !== undefined) {
                                previewElement.data = response?.data;
                                previewElement.documentType = DocumentType.IMAGE;
                                this._displayPreview = true;
                                previewElement.isValid = true;
                                if (response?.filename !== undefined) {
                                    previewElement.url = "/doc/data/" + response.authKey;
                                }
                            }
                        });
    
                    });
                }
            }
    
            this.updateState();
        }
    }

    updateState() {
        if (this.document.uploadedDocument && this.document.collected) {
            console.log("Mark doc %s as already submitted", this.document.id);
            this._uploadDisabled = true;
            this._displayStatus = true;
            this._statusText = "Déjà soumis";
            this._statusClasses = "already-submit-txt";
            this._displayDelCmd = false;
            this._upload.maxFiles = 0;
        } else if (this.document.uploadedDocument) {
            console.log("Mark doc %s as in progress", this.document.id);
            this._displayDelCmd = true;
            this._displayStatus = true;
            this._statusText = "En attente";
            this._upload.maxFiles = 0;
        } else if (this.document.collected) {
            console.log("Mark doc %s as already provided", this.document.id);
            //special case: document was marked as collected but there is no uploadedDocument, this is
            // a feature added in b2b. We should marked this as already provided and ignore this doc
            this._uploadDisabled = true;
            this._displayStatus = true;
            this._statusText = "Déjà fournis";
            this._statusClasses = "already-submit-txt";
            this._displayDelCmd = false;
            this._upload.maxFiles = 0;
        } else {
            console.log("Mark doc %s as to be provided", this.document.id);
            this._displayStatus = true;
            this._statusText = "A remplir";
            this._statusClasses = "state-pending-txt";
            this._displayDelCmd = false;
        }
        console.log("%s updateState called", this.document.id);
    }


    static styles = css`
        .hidden {
            display:none !important;
        }
        .upload-buttons {
            display:flex;
            column-gap: 0.2em;
            justify-content: center;
        }
    `;

}

export type UploadOkEvent = CustomEvent<{ isValid: boolean }>;

export type FlushDocumentsStateEvent = CustomEvent<{}>;