import { EndpointRequestInit as EndpointRequestInit_1 } from "@hilla/frontend";
import client_1 from "./connect-client.default.js";
import type Document_1 from "./oxynoia/documents/collection/b2c/model/Document.js";
import type DocumentStatus_1 from "./oxynoia/documents/collection/b2c/model/DocumentStatus.js";
async function cancelFile_1(collectId: string | undefined, transactionId: string | undefined, init?: EndpointRequestInit_1): Promise<boolean> { return client_1.call("FileUploadEndpoint", "cancelFile", { collectId, transactionId }, init); }
async function fileHasResponse_1(transactionId: string | undefined, init?: EndpointRequestInit_1): Promise<boolean> { return client_1.call("FileUploadEndpoint", "fileHasResponse", { transactionId }, init); }
async function fileHasResponseGlobal_1(transactionId: string | undefined, init?: EndpointRequestInit_1): Promise<boolean> { return client_1.call("FileUploadEndpoint", "fileHasResponseGlobal", { transactionId }, init); }
async function getDocumentsSavedButNotSubmitted_1(collectId: string | undefined, init?: EndpointRequestInit_1): Promise<Array<Document_1 | undefined> | undefined> { return client_1.call("FileUploadEndpoint", "getDocumentsSavedButNotSubmitted", { collectId }, init); }
async function getFileResponse_1(transactionId: string | undefined, init?: EndpointRequestInit_1): Promise<DocumentStatus_1 | undefined> { return client_1.call("FileUploadEndpoint", "getFileResponse", { transactionId }, init); }
async function getFileResponseGlobal_1(transactionId: string | undefined, init?: EndpointRequestInit_1): Promise<string | undefined> { return client_1.call("FileUploadEndpoint", "getFileResponseGlobal", { transactionId }, init); }
async function mergeFilesAndStartCheck_1(category: string | undefined, collectId: string | undefined, transactionId: string | undefined, docIds: Array<number | undefined> | undefined, init?: EndpointRequestInit_1): Promise<string | undefined> { return client_1.call("FileUploadEndpoint", "mergeFilesAndStartCheck", { category, collectId, transactionId, docIds }, init); }
async function readjustTIDs_1(collectId: string | undefined, validTids: Array<string | undefined> | undefined, init?: EndpointRequestInit_1): Promise<void> { return client_1.call("FileUploadEndpoint", "readjustTIDs", { collectId, validTids }, init); }
async function startFlow_1(collectId: string | undefined, init?: EndpointRequestInit_1): Promise<void> { return client_1.call("FileUploadEndpoint", "startFlow", { collectId }, init); }
async function uploadPages_1(collectId: string | undefined, transactionID: string | undefined, files: Array<Document_1 | undefined> | undefined, init?: EndpointRequestInit_1): Promise<string | undefined> { return client_1.call("FileUploadEndpoint", "uploadPages", { collectId, transactionID, files }, init); }
export { cancelFile_1 as cancelFile, fileHasResponse_1 as fileHasResponse, fileHasResponseGlobal_1 as fileHasResponseGlobal, getDocumentsSavedButNotSubmitted_1 as getDocumentsSavedButNotSubmitted, getFileResponse_1 as getFileResponse, getFileResponseGlobal_1 as getFileResponseGlobal, mergeFilesAndStartCheck_1 as mergeFilesAndStartCheck, readjustTIDs_1 as readjustTIDs, startFlow_1 as startFlow, uploadPages_1 as uploadPages };
