import '@cdui/button';
import '@cdui/document';
import '@cdui/documents-section-header';
import '@cdui/nav';
import { Notification } from '@vaadin/notification';
import "@vaadin/upload";
import { FileUploadEndpoint, SignatureEndpoint, SubscriptionEndpoint } from "Frontend/generated/endpoints";
import RequiredDocument from "Frontend/generated/oxynoia/documents/collection/b2b/model/RequiredDocument";
import Subscription from "Frontend/generated/oxynoia/documents/collection/b2b/model/Subscription";
import { appStore } from "Frontend/stores/app-store";
import { LitElement, html, nothing } from "lit";
import { customElement, state } from "lit/decorators.js";
import './docs-by-category';
import './document-upload';
import './global-drop-zone';
import './my-information';
import { StepChangedEvent } from "./step-event-dispatcher";
import './sign-docs-form';
import '../nav/cdui-nav';
import { CduiNavElement } from '../nav/cdui-nav';

@customElement("document-view")
export class DocumentView extends LitElement {

    @state()
    collectId: string = "";

    _from: string = "";

    _signatureRequestId: string = "";

    @state()
    uploadInProgress: boolean = false;

    @state()
    subscription: Subscription = {};

    @state()
    statusByDocument: Map<string, boolean> = new Map();

    @state()
    private _currentStep: number = 0;

    @state()
    private _validation: boolean = false;


    render() {
        window.onbeforeunload = function () {
            return "Même si vos documents n'ont pas été soumis, ils seront conservés en l'état jusqu'à soumission.";
        };
        //calculate currentStep before rendering
        var allCatsList = Array.from(new Set(this.subscription.missingDocuments?.map(doc => doc?.category)).add("others")).filter(cat => cat !== undefined) as string[];

        var priorityDocsToCollect = this.subscription.missingDocuments !== undefined ? this.getTotalDocumentsToCollect(this.subscription.missingDocuments.filter(document => document !== undefined && document.priority && !document.toSign && document.category !== "signature")): 0;
        var otherDocsToCollect = this.subscription.missingDocuments !== undefined ? this.getTotalDocumentsToCollect(this.subscription.missingDocuments.filter(document => document !== undefined && !document.priority && !document.toSign)) : 0;
        var docsToSign = this.subscription.missingDocuments !== undefined ? this.subscription.missingDocuments?.filter(md => md?.toSign).length : 0;
        var hasPriorityDocs = allCatsList && priorityDocsToCollect > 0;
        var hasOtherDocs = otherDocsToCollect > 0;
        var hasDocsToSign = docsToSign > 0;

        var navElements: CduiNavElement[] = [
            {   
                title: "Mes informations",
                hasDocs: false
            },
            {   
                title: "Documents prioritaires",
                hasDocs: hasPriorityDocs,
                docsToCollect: priorityDocsToCollect
            },
            {   
                title: "Autres documents",
                hasDocs: hasOtherDocs,
                docsToCollect: otherDocsToCollect
            },
            {   
                title: "Signature documents",
                hasDocs: hasDocsToSign,
                docsToCollect: docsToSign
            },
            {   
                title: "Confirmation & envoi",
                hasDocs: false
            }
        ]

        var retstr = html`
            <div class="cdui-page">
                <cdui-nav-v2 .navElements=${navElements} @step-changed=${(e: StepChangedEvent) => this._currentStep = e.detail.step} .currentStep=${this._currentStep}>
                    <img slot="logo" src="images/logo.png">
                    <img slot="small-logo" src="images/logo_only.png">
                </cdui-nav-v2>

                <div style="width: 100%;">
                    <vaadin-vertical-layout .hidden=${this._currentStep != 0}>
                        <my-information style="width:100%" .subscription=${this.subscription} @step-changed=${(e: StepChangedEvent) => this._currentStep = e.detail.step}></my-information>
                    </vaadin-vertical-layout>

                    <vaadin-vertical-layout>
                        <h1 class="cdui-title" .hidden=${this._currentStep != 1}>Documents PRIORITAIRES à joindre au dossier</h1>
                        <h1 class="cdui-title" .hidden=${this._currentStep != 2}>Documents additionnels à joindre au dossier</h1>

                        <li class="cdui-subtitle" .hidden=${![1,2].includes(this._currentStep)} >
                            Voici les pièces réclamées par l'organisme ${this.subscription.tenant?.name} pour la constitution de votre dossier. 
                            Merci de les vérifier et de joindre les pièces manquantes.
                        </li>

                        <global-drop-zone  
                            .hidden=${![1,2].includes(this._currentStep)} 
                            style="width:100%" 
                            .collectId=${this.subscription.collectId !== undefined ? this.subscription.collectId : ""} 
                            .subscription=${this.subscription} 
                            @subscription-changed=${(e: CustomEvent) => this.getSubscription()}
                        >
                        </global-drop-zone>

                        <docs-by-category 
                            .hidden="${this._currentStep != 1}"
                            style="width:100%"
                            previousStep="0" 
                            nextStep="2" 
                            .categories=${allCatsList.filter(category => category != "signature" && category != "others")}
                            .priority=${true}
                            .collectId=${this.collectId}
                            .missingDocuments=${this.subscription.missingDocuments as RequiredDocument[]}
                            .displayDocs=${hasPriorityDocs}
                            @step-changed=${(e: StepChangedEvent) => this._currentStep = e.detail.step}
                        ></docs-by-category>

                        <docs-by-category 
                            .hidden="${this._currentStep != 2}"
                            style="width:100%"
                            previousStep="1" 
                            nextStep="3" 
                            .categories=${allCatsList.filter(category => category != "signature")}
                            .priority=${false}
                            .collectId=${this.collectId}
                            .missingDocuments=${this.subscription.missingDocuments as RequiredDocument[]}
                            .displayDocs=${true}
                            @step-changed=${(e: StepChangedEvent) => this._currentStep = e.detail.step}
                        ></docs-by-category>
                    </vaadin-vertical-layout>

                    <vaadin-vertical-layout .hidden="${this._currentStep != 3}">
                        <sign-docs-form
                            style="width:100%"
                            previousStep="2"
                            nextStep="4"
                            .collectId=${this.collectId}
                            .docsToSign=${this.subscription.missingDocuments?.filter(doc => doc?.toSign && !doc.uploadedDocument?.sign?.signed)}
                            @step-changed=${(e: StepChangedEvent) => this._currentStep = e.detail.step}
                        ></sign-docs-form>
                    </vaadin-vertical-layout>

                    <vaadin-vertical-layout .hidden="${this._currentStep != 4}">
                        <h1 class="cdui-title">Confirmation</h1>

                        <!-- <div class"cdui-documents-list">
                            <cdui-document done class="urgent">RELEVÉ DE COMPTE</cdui-document>
                        </div> -->

                        <div class="cdui-vertical-layout">
                            <div style="display: flex; gap: 10px; align-items: baseline;">
                                <input type="checkbox" id="validation-checkbox" .checked="${this._validation}" @click="${(e: MouseEvent) => this._validation = (e.target! as HTMLInputElement).checked}" />
                                <label for="validation-checkbox">J'autorise la transmission de mes documents.</label>
                            </div>

                            <cdui-button
                                    @click="${() => { if (this._validation) this.uploadFiles() }}"
                                    ?disabled="${!this._validation}"
                            >
                                Transmettre mes documents
                            </cdui-button>

                            <vaadin-vertical-layout .hidden=${!this.uploadInProgress}>
                                <p>Veuillez patienter svp pendant que nous analysons vos documents...</p>
                            </vaadin-vertical-layout>
                        </div>
                    </vaadin-vertical-layout>
                </div>
           </div>
        `;
        return retstr;
    }

    getTotalDocumentsToCollect(requiredDocuments: Array<RequiredDocument | undefined> | undefined) {
        if (requiredDocuments !== undefined && requiredDocuments.length > 0) {
            return requiredDocuments.length;
        } else {
            return 0;
        }
    }

    signDocument(e: CustomEvent) {
        var documents = this.subscription.missingDocuments?.filter(doc => doc?.toSign && !doc.uploadedDocument?.sign?.signed);
        SignatureEndpoint.signDocuments(this.subscription.collectId, documents).then(uri => {
            if (uri !== undefined && uri?.length > 0) {
                window.onbeforeunload = function () { };
                window.location.href = uri;
            }
        });
    }

    async uploadFiles() {
        // this.uploadInProgress = true;
        FileUploadEndpoint.startFlow(this.collectId);
        window.onbeforeunload = function () { };
        window.location.href = '/docs-end';
        //disable upload button + display progress bar
        // this.uploadInProgress = false;
        //redirect 

    }

    async getSubscription() {
        if (this._signatureRequestId != null && this._signatureRequestId.length > 0) {
            this._currentStep = 4;
            SignatureEndpoint.retrieveDocuments(this.collectId, this._signatureRequestId).then((response) => {
                SubscriptionEndpoint.getSubscriptionByCollectId(this.collectId, this._from != null ? this._from : "").then((response) => {
                    if (response != undefined) {
                        this.subscription = response;
                    }
                });
            });

        } else {
            var serverResponse = await SubscriptionEndpoint.getSubscriptionByCollectId(this.collectId, this._from != null ? this._from : "");
            if (serverResponse != undefined) {
                this.subscription = serverResponse;
                appStore.setSubscription(serverResponse);
                // this.restoreDocumentsState();
            } else {
                Notification.show("Dossier non trouvé.")
            }
        }
    }

    connectedCallback() {
        super.connectedCallback();

        this.collectId = location.pathname.split("/").pop() as string;
        if (location.search) {
            let searchParams = new URLSearchParams(location.search);
            if (searchParams.has("from") && searchParams.get("from") !== null) {
                this._from = searchParams.get("from") as string;
            }
            if (searchParams.has("signature_request_id") && searchParams.get("signature_request_id") !== null) {
                this._signatureRequestId = searchParams.get("signature_request_id") as string;
            }
        }

        this.classList.add(
            'w-full',
            'h-full',
        );
        this.getSubscription();
    }

    protected createRenderRoot(): HTMLElement | DocumentFragment {
        return this;
    }
    
}