import { html } from "lit";
import { View } from "../view";
import { BeforeEnterObserver, RouterLocation } from "@vaadin/router";
import { customElement } from "lit/decorators.js";

@customElement("preview-view")
export class PreviewView extends View implements BeforeEnterObserver {

    backgroundColor!: string | null;

    onBeforeEnter(location: RouterLocation) {
        if (location.search) {
            let searchParams = new URLSearchParams(location.search);
            if (searchParams.has("background") && searchParams.get("background") != null) {
                this.backgroundColor = searchParams.get("background");
            }
        }

    }

    render() {
        return html` 
            <h3 style="width:100%">Prévisualisation du résultat</h3>
            <p>Ici vous pouvez prévisualiser le rendu que vos clients verront lorsqu'ils navigueront sur cette interface.</p>
        `;
    }

    connectedCallback() {
        super.connectedCallback();
        if (this.backgroundColor) {
            this.style.setProperty("--blue-gradient", this.backgroundColor);
        }
    }

    createRenderRoot() {
        return this;
    }
    

    

}