import '@vaadin/button';
import '@vaadin/notification';
import { Notification } from '@vaadin/notification';
import '@vaadin/text-field';
import { html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { View } from '../../views/view.js';
import * as SubscriptionEndpoint from 'Frontend/generated/SubscriptionEndpoint.js';
import * as FormEndpoint from 'Frontend/generated/FormEndpoint.js';
import Subscription from 'Frontend/generated/oxynoia/documents/collection/b2b/model/Subscription.js';

@customElement('hello-world-view')
export class HelloWorldView extends View {
  name = '';

  @state()
  subscription: Subscription = {
  };

  connectedCallback() {
    super.connectedCallback();
    this.classList.add('flex', 'p-m', 'gap-m', 'items-end');
  }

  render() {
    return html`
      <vaadin-text-field label="Your name" @value-changed=${this.nameChanged}></vaadin-text-field>
      <vaadin-button @click=${this.getSubscription}>Say hello</vaadin-button>
      <span>Prénom du client trouvé: ${this.subscription.subscriber ? this.subscription.subscriber.firstName : ''}</span>
      <vaadin-button @click=${this.testForm}>Test formulaire</vaadin-button>
    `;
  }

  nameChanged(e: CustomEvent) {
    this.name = e.detail.value;
  }

  async getSubscription() {
    const serverResponse = await SubscriptionEndpoint.getSubscriptionByCollectId(this.name,"");
    if (serverResponse !== undefined) {
      this.subscription = serverResponse;
    }
    Notification.show("Subscription found");
  }

  async testForm(){
    await FormEndpoint.createForm();
  }

}
