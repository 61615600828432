import { UploadI18n } from "@vaadin/upload";

export const frenchUploadI18n: UploadI18n = {
    dropFiles: {
        one: "Glisser  ici",
        many: "Glisser  ici"
    },
    addFiles: {
        one: "Ajouter un fichier",
        many: "Ajouter des fichiers"
    },
    error: {
        tooManyFiles: "Trop de fichiers",
        fileIsTooBig: "Fichier trop volumineux",
        incorrectFileType: "Type de fichier incorrect"
    },
    uploading: {
        status: {
            connecting: "Connexion...",
            stalled: "En attente...",
            processing: "En cours...",
            held: "En attente"
        },
        remainingTime: {
            prefix: "temps restant: ",
            unknown: "temps restant inconnu"
        },
        error: {
            serverUnavailable: "Serveur indisponible",
            unexpectedServerError: "Erreur serveur inattendue",
            forbidden: "Interdit"
        }
    },
    units: {
      size: ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      sizeBase: 1000,
    },
  };