import { applyTheme } from 'Frontend/generated/theme';
import { html, LitElement, css, nothing } from 'lit';
import { customElement, property, state } from "lit/decorators.js";
import { StepEventEventDispatcher } from '../document/step-event-dispatcher';

@customElement("cdui-nav-v2")
class CduiNav extends StepEventEventDispatcher {

    @property({type: Array})
    navElements: CduiNavElement[] = [];

    @state()
    currentStep: number = 0;

    render() {
        return html`
        <div id="container">
            <div id="header">
                <slot name="logo"></slot>
                <slot name="small-logo"></slot>
            </div>
            <ol>
                ${this.navElements.map(navElement => {
                    return html`<li @click=${() => {
                        this.currentStep = this.navElements.indexOf(navElement);
                        this.goToStep(this.currentStep);
                    }} active="${this.currentStep === this.navElements.indexOf(navElement) || nothing}">${navElement.title} ${navElement.hasDocs ? html`<div style="margin-left:10px" id="pastille">${navElement.docsToCollect}</div>` : html``}</li>`;
                })}
            </ol>
        </div>
        `;
    }

    connectedCallback(): void {
        super.connectedCallback();
        applyTheme(this.renderRoot);
    }

    static get styles() {
        return [
            css`
                @import url('https://fonts.googleapis.com/css2?family=Quicksand');

                :host {
                    --primary-color: var(--cdui-primary-color, #006F8E);
                    --secondary-color: var(--cdui-secondary-color, #FF8269);
                }

                #container {
                    width: 40px;
                    height: calc(100% - 60px);
                    background: var(--primary-color);
                    display: flex;
                    flex-direction: column;
                    padding: 30px 0;
                    border-radius: 20px;
                    font-family: 'Quicksand', sans-serif;
                    overflow: hidden;
                }

                #header {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 30px;
                }
                ol {
                    counter-reset: section; /* Crée un nouveau compteur nommé 'section' */
                    list-style-type: none; /* Enlève le style de liste par défaut */
                }
                ol li {
                    counter-increment: section; /* Incrémente le compteur 'section' */
                    position: relative; /* Positionne relativement pour le pseudo-élément */
                    padding-left: 2.5em; /* Ajoute de l'espace pour le cercle et la ligne */
                    list-style: none; /* Enlève le style de liste par défaut */
                    box-sizing: border-box;
                }
                ol li::before {
                    content: counter(section); /* Affiche le compteur */
                    background-color: white; /* Définit la couleur de fond du cercle en blanc */
                    color: var(--primary-color); /* Définit la couleur du texte en bleu */
                    font-weight: bold; /* Rend le texte en gras */
                    width: 1.5em; /* Définit la largeur du cercle */
                    height: 1.5em; /* Définit la hauteur du cercle */
                    line-height: 1.5em; /* Centre le texte verticalement */
                    border-radius: 50%; /* Rend le fond en forme de cercle */
                    text-align: center; /* Centre le texte horizontalement */
                    display: inline-block; /* Permet au cercle de s'aligner correctement avec le texte */
                    margin-right: 1em; /* Ajoute un espace entre le cercle et le texte de la liste */
                    position: absolute; /* Positionne absolument par rapport à l'élément li */
                    left: 5px; /* Alignement à gauche */
                    z-index: 2; /* Assure que le cercle se trouve au-dessus de la ligne */
                }

                ol li[active]::before {
                    color: #00ffff; /* Définit la couleur du texte en bleu */
                    left: -5px;
                }

                ol li:not(:last-child)::after {
                    content: ''; /* Pseudo-élément vide pour la ligne */
                    position: absolute; /* Positionne absolument par rapport à l'élément li */
                    left: 0.95em; /* Positionne au centre du cercle */
                    top: 50%; /* Démarre du milieu du cercle */
                    width: 4px; /* Épaisseur de la ligne */
                    height: calc(100% - 1.5em / 2 + 20px); /* Hauteur jusqu'à l'élément suivant moins la hauteur du cercle */
                    background-color: white; /* Couleur de la ligne */
                    z-index: 1; /* Assure que la ligne se trouve derrière le cercle */
                }

                ol li[active]:not(:last-child)::after {
                    left: 0.3em; /* Positionne au centre du cercle */
                }

                li {
                    width: calc(100% - 10px);
                    height: 60px;
                    color: white;
                    text-decoration: none;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    padding-left: 10px;
                    cursor: pointer;
                }

                ol{
                    padding-inline-start: 0;
                }

                li:hover {
                    font-weight: bold;
                }

                li[active] {
                    width: 100%;
                    font-weight: bold;
                    padding-left: 1.9em;
                    border-left: 10px solid var(--secondary-color);
                    background: linear-gradient(90deg, rgba(0,111,142,1) 0%, rgba(196,227,235,1) 100%);
                }

                ::slotted( img[slot="small-logo"] ) {
                    width: 30px;
                }

                ::slotted(img[slot="logo"]) {
                    height: 52px;
                }

                
                @media (min-width: 1000px) {
                    #container {
                        width: 321px;
                    }
                    ::slotted(img[slot="small-logo"] ) {
                        display: none;
                    }
                    ::slotted(img[slot="logo"]) {
                        display: block;
                    }
                }

                @media (max-width: 999px) {
                    ::slotted(img[slot="small-logo"] ) {
                        display: block;
                    }
                    ::slotted(img[slot="logo"]) {
                        display: none;
                    }
                }
            `
        ];
    }
}

export interface CduiNavElement {
    title: string;
    hasDocs: boolean;
    docsToCollect?: number;
}