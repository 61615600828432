import { RouterLocation } from '@vaadin/router';
import Subscription from 'Frontend/generated/oxynoia/documents/collection/b2b/model/Subscription';
import { makeAutoObservable } from 'mobx';

export class AppStore {
  applicationName = 'documents-collection-b2c';

  // The location, relative to the base path, e.g. "hello" when viewing "/hello"
  location = '';

  currentViewTitle = '';

  subscription: Subscription | undefined = undefined

  subscriberInformationsValidated: boolean = false

  constructor() {
    makeAutoObservable(this);
  }

  setLocation(location: RouterLocation) {
    const serverSideRoute = location.route?.path == '(.*)';
    if (location.route && !serverSideRoute) {
      this.location = location.route.path;
    } else if (location.pathname.startsWith(location.baseUrl)) {
      this.location = location.pathname.substr(location.baseUrl.length);
    } else {
      this.location = location.pathname;
    }
    if (serverSideRoute) {
      this.currentViewTitle = document.title; // Title set by server
    } else {
      this.currentViewTitle = (location?.route as any)?.title || '';
    }
  }

  setSubscription(subscription: Subscription) {
    this.subscription = subscription
  }

  setSubscriberInformationsValidated(value: boolean) {
    this.subscriberInformationsValidated = value
  }

}

export const appStore = new AppStore();
