import { LitElement, PropertyValues, css, html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import * as pdfjs from 'pdfjs-dist';
const worker = await import('pdfjs-dist/build/pdf.worker.entry');
pdfjs.GlobalWorkerOptions.workerSrc = worker;

@customElement("pdf-viewer")
export class PdfViewer extends LitElement {

    @property()
    public data!: string;

    @query("#canvas-pdf")
    private canvas!: HTMLCanvasElement;

    @state()
    private _progressBarHidden: boolean = false;

    constructor(){
        super();
    }

    render() {
        return html`
            <canvas id="canvas-pdf" width="147" @click="${(e: CustomEvent) => this.loadViewer()}"></canvas>
            <vaadin-progress-bar indeterminate .hidden="${this._progressBarHidden}"></vaadin-progress-bar>
        `;
    }

    shouldUpdate(changedProperties: PropertyValues<this>) {
        changedProperties.forEach((oldValue, propName) => {
            if (propName == 'data') {
                this.loadPdf();
            }
        });
        return true;
    }

    loadViewer(){
        let pdfWindow = window.open("");
        if(pdfWindow != null){
            pdfWindow.document.write(
                "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                encodeURI(this.data) + "'></iframe>"
            );
        }
        
    }

    loadPdf() {
        if (this.data !== undefined) {
            this.data = this.data.replace("data:application/pdf;base64,","");
            var _raw = atob(this.data);
            var _data = new Uint8Array(_raw.length);
            for (var i = 0, j = _raw.length; i < j; ++i) {
                _data[i] = _raw.charCodeAt(i);
            }
            // pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.js', import.meta.url).toString();
            pdfjs.getDocument(_data).promise
                .then(pdf => {
                    pdf.getPage(1).then(page => {
                        var viewport = page.getViewport({ scale: this.canvas.width / page.getViewport({ scale: 1.0 }).width });
                        this.canvas.height = viewport.height;
                        var context = this.canvas.getContext("2d");

                        if (context != null) {
                            var renderContext = {
                                canvasContext: context,
                                viewport: viewport
                            }
                            this._progressBarHidden = true;
                            page.render(renderContext);
                        }
                    }, reason => {
                        console.error("Unable to load pdf");
                    });
                });
        }
    }

    connectedCallback(): void {
        super.connectedCallback();
    }

    static styles = css`
       canvas {
        cursor : pointer;
       }
    `;

}