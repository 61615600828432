import { Router } from '@vaadin/router';
import { routes } from './routes.js';
import { appStore } from './stores/app-store.js';
import { get, registerTranslateConfig, use } from 'lit-translate';

export const router = new Router(document.querySelector('#outlet'));

router.setRoutes(routes);

// Configure lit-translate
const translateConfig = registerTranslateConfig({
    loader: lang => fetch(`/i18n/${lang}.json`).then(res => res.json()),
  });
  use('fr');

window.addEventListener('vaadin-router-location-changed', (e) => {
  appStore.setLocation((e as CustomEvent).detail.location);
  const title = appStore.currentViewTitle;
  if (title) {
    document.title = title + ' | ' + appStore.applicationName;
  } else {
    document.title = appStore.applicationName;
  }
});
