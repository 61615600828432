import '@vaadin/app-layout';
import { AppLayout } from '@vaadin/app-layout';
import '@vaadin/app-layout/vaadin-drawer-toggle';
import '@vaadin/avatar';
import '@vaadin/icon';
import '@vaadin/menu-bar';
import '@vaadin/scroller';
import '@vaadin/side-nav';
import '@vaadin/tabs';
import '@vaadin/tabs/vaadin-tab';
import '@vaadin/vaadin-lumo-styles/vaadin-iconset';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { router } from '../index.js';
import { views } from '../routes.js';
import { appStore } from '../stores/app-store.js';
import { Layout } from './view.js';
import { applyTheme } from 'Frontend/generated/theme.js';
import { BeforeEnterObserver, RouterLocation } from '@vaadin/router';
import { TenantStyleEndpoint } from 'Frontend/generated/endpoints.js';

interface RouteInfo {
    path: string;
    title: string;
    icon: string;
}

@customElement('preview-layout')
export class PreviewLayout extends Layout implements BeforeEnterObserver {

    backgroundColor!: string | null;

    onBeforeEnter(location: RouterLocation) {
        if (location.search) {
            let searchParams = new URLSearchParams(location.search);
            if (searchParams.has("background") && searchParams.get("background") != null) {
                this.backgroundColor = searchParams.get("background");
            }
        }
    }

    render() {
        return html`
      <vaadin-app-layout class="h-full w-full">
        <header slot="navbar">
           <img src="/icons/icon.png" />
          <h1>Collecte de documents</h1>
        </header>
        <vaadin-vertical-layout class="main-content" theme="padding spacing" style="height:100%">
            <slot><!-- The router puts views here --></slot>
        </vaadin-vertical-layout>
      </vaadin-app-layout>
    `;
    }

    connectedCallback() {
        super.connectedCallback();
        applyTheme(this.renderRoot);
        if (this.backgroundColor) {
            this.style.setProperty("--blue-gradient", this.backgroundColor);
        }
        this.classList.add('block', 'h-full');
        this.reaction(
            () => appStore.location,
            () => {
                AppLayout.dispatchCloseOverlayDrawerEvent();
            }
        );
    }

    private getMenuRoutes(): RouteInfo[] {
        return views.filter((route) => route.title) as RouteInfo[];
    }
}
