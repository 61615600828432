import { LitElement } from "lit";

export type StepChangedEvent = CustomEvent<{ step: number }>;

export class StepEventEventDispatcher extends LitElement{

    goToStep(step: number){
        var event = new CustomEvent('step-changed', {
            detail: {
                step: step
            }
        }) as StepChangedEvent;
        this.dispatchEvent(event);
    }

}