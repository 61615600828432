import Subscription from "Frontend/generated/oxynoia/documents/collection/b2b/model/Subscription";
import { applyTheme } from "Frontend/generated/theme";
import { LitElement, html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { StepEventEventDispatcher } from "./step-event-dispatcher";

@customElement("my-information")
class MyInformation extends StepEventEventDispatcher {

    @state()
    private subscription!: Subscription;

    render() {
        return html`
            <h1 class="cdui-title">Mes informations</h1>

            <div class="cdui-card" style="width: calc(100% - 60px);">
                <h2>Informations envoyées par ${this.subscription.author}</h2>

                <p>Merci de vérifier l'exactitude de ces informations.</p>

                <table class="cdui-table">
                    <tr>
                        <th>Prénom</th>
                        <td>${this.subscription.subscriber?.firstName}</td>
                    </tr>
                    <tr>
                        <th>Nom</th>
                        <td>${this.subscription.subscriber?.lastName}</td>
                    </tr>
                    <tr>
                        <th>Adresse email</th>
                        <td>${this.subscription.subscriber?.email}</td>
                    </tr>
                    <tr>
                        <th>Téléphone</th>
                        <td>${this.subscription.subscriber?.phoneNumber}</td>
                    </tr>
                    <tr>
                        <th>Adresse</th>
                        <td>${this.subscription.subscriber?.address}, ${this.subscription.subscriber?.zipCode} ${this.subscription.subscriber?.city}</td>
                    </tr>
                </table>
            </div>

            
            ${this.subscription.subscriber?.lastName != undefined ? html`
                            <div class="cdui-pagination">
                                <div></div>
                                <div class="cdui-pagination-link" @click=${() => this.goToStep(1)}>
                                    <span>Suivant</span>
                                    <cdui-button class="round">ᐳ</cdui-button>
                                </div>
                            </div>`
                : html``}
        `;
    }
    
    connectedCallback(): void {
        super.connectedCallback();
        applyTheme(this.renderRoot);
    }

}