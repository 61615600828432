import { css, html, LitElement, PropertyValues, TemplateResult } from "lit";
import { customElement, queryAsync, state, property, query } from 'lit/decorators.js';
import Sortable, { SortableEvent } from 'sortablejs';

@customElement("multi-page-selector")
export class MultiPagesSelector extends LitElement {

    @state()
    private selections: PreviewData[] = [];

    @queryAsync("#draggable-list")
    private draggableList!: Promise<HTMLElement>;

    @property({ attribute: false })
    deleteRenderer: (item: PreviewData) => TemplateResult = (item) => html`Supprimer`;

    @property({ attribute: false })
    itemRenderer: (item: PreviewData) => TemplateResult = (item) => html``;

    @property()
    additionalTypes: string = "";

    @query("input")
    private _input!: HTMLInputElement;

    handleFileChange(event: Event) {
        const input = event.target as HTMLInputElement;
        if (input.files) {
            const filesArray = Array.from(input.files);
            filesArray.forEach(file => {
                const reader = new FileReader();
                reader.onload = () => {
                    this.selections = [...this.selections, {
                        data: reader.result as string,
                        filename: file.name,
                        id: this.generateId()
                    }];
                };
                reader.readAsDataURL(file);
            });
        }
    }

    private generateId(): string {
        return `${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
    }

    private deleteItem(id: string) {
        this.selections = this.selections.filter(selection => selection.id !== id);
        console.log(`Supprimer l'élément  ${id}`);
    }

    clearSelections() {
        this.selections = [];
        this._input.value = "";
    }

    render() {
        return html`
            <form>
                <input part="input-file" capture="camera" type="file" accept="${"image/jpeg,.jpeg,.jpg,image/png,.png,.jpg,image/tiff,.tiff,.tif,"+this.additionalTypes}" @change="${this.handleFileChange}" multiple />
            </form>
            <div id="draggable-list" part="draggable-list">
                ${this.selections.map(selection => {
            return html`
                        <div class="preview" id="${selection.id}" part="preview">
                            ${this.itemRenderer(selection)}
                            <span part="delcmd">
                                <cdui-button 
                                    @click=${() => this.deleteItem(selection.id)}
                                    >${this.deleteRenderer(selection)}
                                </cdui-button>
                            </span>
                        </div>
                        `;
        })}
            </div>
            <div id="footer" part="footer">
                <cdui-button 
                    ?disabled=${this.selections.length == 0}
                    @click=${() => this.pagesSelected()}
                    >Utiliser ces documents
                </cdui-button>
            </div>
        `;
    }

    private pagesSelected() {
        this.dispatchEvent(new CustomEvent("pages-selected", {
            detail: {
                pages: this.selections
            }
        } as PagesSelectedEvent))
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        this.draggableList.then(list => {
            let before: ChildNode | null;
            Sortable.create(list, {
                animation: 150,
                onStart: (e) => {
                    before = e.item.previousSibling;
                },
                onEnd: (evt: SortableEvent) => {
                    // put the item back
                    if (before !== null) {
                        before.after(evt.item);
                    }
                    if (evt.oldIndex !== undefined && evt.newIndex !== undefined && evt.oldIndex !== evt.newIndex) {
                        const movedItem = this.selections.splice(evt.oldIndex, 1)[0];
                        this.selections.splice(evt.newIndex, 0, movedItem);
                        this.requestUpdate();
                    }
                    console.log(`Element déplacé de l'index ${evt.oldIndex} à l'index ${evt.newIndex}`);
                }
            });
        });
    }


    static get styles() {
        return [
            css`
                .preview {
                    width: 147px;
                    display: inline-block;
                    cursor: move;
                }
                #draggable-list {
                    display:flex;
                }   
                input[type=file] {
                    min-width:200px;
                }
                #footer {
                    display:flex;
                    justify-content: center;
                }
            `
        ];
    }
}

export interface PreviewData {
    data: string;
    filename: string;
    id: string;
}

export type PagesSelectedEvent = CustomEvent<{ pages: PreviewData[] }>;
