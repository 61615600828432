import { html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { View } from '../../views/view.js';
import { Binder, field } from '@hilla/form';
import type { FormLayoutResponsiveStep } from '@vaadin/form-layout';
import '@vaadin/date-picker';
import '@vaadin/tabs';
import '@vaadin/tabsheet';
import '@vaadin/form-layout';
import '@vaadin/checkbox';
import '@vaadin/vertical-layout';
import '@vaadin/integer-field';
import PersonModel from 'Frontend/generated/oxynoia/documents/collection/b2b/model/PersonModel';
import type Person_1 from "Frontend/generated/oxynoia/documents/collection/b2b/model/Person.js";
import * as SubscriptionEndpoint from 'Frontend/generated/SubscriptionEndpoint.js';
// import { Template, BLANK_PDF, generate } from '@pdfme/generator';
import { FormEndpoint } from 'Frontend/generated/endpoints.js';
import { displayFormAsPdf } from 'Frontend/functions/pdf.js';
import Form from 'Frontend/generated/oxynoia/documents/collection/b2c/model/Form.js';


@customElement('big-form-view')
export class BigFormView extends View {

    @property()
    subscriber!: Person_1;

    @property()
    subscriptionId!: number;

    @state()
    illnessValue: boolean = false;

    @state()
    invalidityValue: boolean = false;

    @state()
    radioButtonValuesMap: Map<string, string> = new Map<string, string>()

    @state()
    tabSelectedIndex: number = 0

    @state()
    pdfUrl: string = ""

    @state()
    form: Form | undefined = undefined

    @state()
    jsonForm: string = `[
        {
            "pageNumber": 0,
            "title": "Partie 1",
            "formElements": [
                {
                    "pdfPageNumber": 0,
                    "type": "radio",
                    "label": "Bénéficiez vous ou avez-vous bénéficié d'une prise en charge à 100% pour une affection longue durée par un organisme de sécurité sociale, RSI ou équivalent (exonération du ticket modérateur) ?",
                    "options": {
                        "Oui": {
                            "position": {
                                "x": 98.63,
                                "y": 127.74
                            }
                        },
                        "Non": {
                            "position": {
                                "x": 89.69,
                                "y": 127.79
                            }
                        }
                    },
                    "id": "maladie_long_duree",
                    "requiredToContinue": true
                },
                {
                    "pdfPageNumber": 0,
                    "type": "text-field",
                    "label": "Pourquoi ?",
                    "maxNumberOfCharacters": "200",
                    "onlyShowIf": {"id": "maladie_long_duree", "value": "Oui"}
                },
                {
                    "pdfPageNumber": 0,
                    "type": "text-field",
                    "label": "Depuis le ?",
                    "onlyShowIf": {"id": "maladie_long_duree", "value": "Oui"}
                },
                {
                    "pdfPageNumber": 0,
                    "type": "radio",
                    "label": "Êtes-vous ou allez-vous être titulaire d'une rente ou d'une pension d'invalidité (joindre les justificatifs) ?",
                    "options": {
                        "Oui": {
                            "position": {
                                "x": 98.53,
                                "y": 141.39
                            }
                        },
                        "Non": {
                            "position": {
                                "x": 89.85,
                                "y": 141.44
                            }
                        }
                    },
                    "id": "pension_invalidite",
                    "requiredToContinue": true
                },
                {
                    "pdfPageNumber": 0,
                    "type": "number-field",
                    "label": "Taux de pension ?",
                    "maxNumberOfCharacters": "5",
                    "onlyShowIf": {"id": "pension_invalidite", "value": "Oui"},
                    "_comment": "5 charactères pour permettre quelque chose comme 76.50"
                }
            ]
        },
        {
            "pageNumber": 1,
            "title": "Partie 2",
            "formElements": [
                {
                    "pdfPageNumber": 0,
                    "type": "radio",
                    "label": "Êtes vous actuellement en arrêt de travail sur prescription médicale pour raison de santé ?",
                    "options": {
                        "Oui": {
                            "position": {
                                "x": 98.68,
                                "y": 153.98
                            }
                        },
                        "Non": {
                            "position": {
                                "x": 89.74,
                                "y": 153.5
                            }
                        }
                    },
                    "id": "arret_de_travail",
                    "requiredToContinue": true
                },
                {
                    "pdfPageNumber": 0,
                    "type": "text-field",
                    "label": "Pourquoi ?",
                    "maxNumberOfCharacters": "200",
                    "onlyShowIf": {"id": "arret_de_travail", "value": "Oui"}
                },
                {
                    "pdfPageNumber": 0,
                    "type": "text-field",
                    "label": "Depuis quand ?",
                    "onlyShowIf": {"id": "arret_de_travail", "value": "Oui"}
                },
                {
                    "pdfPageNumber": 0,
                    "type": "radio",
                    "label": "Avez-vous eu un ou plusieurs arrêts de travail de plus de 30 jours consécutifs au cours des 5 dernières années sur prescription médicale pour raison de santé ?",
                    "options": {
                        "Oui": {
                            "position": {
                                "x": 98.57,
                                "y": 165.51
                            }
                        },
                        "Non": {
                            "position": {
                                "x": 89.9,
                                "y": 165.3
                            }
                        }
                    },
                    "id": "trente_jours_consecutifs",
                    "requiredToContinue": true
                },
                {
                    "pdfPageNumber": 0,
                    "type": "text-field",
                    "label": "Pourquoi ?",
                    "maxNumberOfCharacters": "200",
                    "onlyShowIf": {"id": "trente_jours_consecutifs", "value": "Oui"}
                },
                {
                    "pdfPageNumber": 0,
                    "type": "text-field",
                    "label": "Quand ?",
                    "onlyShowIf": {"id": "trente_jours_consecutifs", "value": "Oui"}
                },
                {
                    "pdfPageNumber": 0,
                    "type": "text-field",
                    "label": "Durée ?",
                    "onlyShowIf": {"id": "trente_jours_consecutifs", "value": "Oui"}
                }
            ]
        },
        {
            "pageNumber": 2,
            "title": "Partie 3",
            "formElements": [
                {
                    "pdfPageNumber": 0,
                    "type": "radio",
                    "label": "Êtes-vous ou avez-vous été atteint d'une affection, d'une maladie chronique ou récidivante, de séquelles d'un accident, d'une invalidité ou infirmité quelle qu'elle soit ? Notamment : affections endocriniennes, métaboliques, génito-urinaires, cardiovasculaires, digestives, respiratoires, de l'ouïe, de la vue (hors myopie, astigmatisme ou presbytie légers), du système nerveux, neuropsychiques, d'hypertension artérielle, de diabète, d'hépatite, de séropositivité, de cancer, d'affection tumorale ou maladie du sang ?",
                    "options": {
                        "Oui": {
                            "position": {
                                "x": 98.73,
                                "y": 181.81
                            }
                        },
                        "Non": {
                            "position": {
                                "x": 90.05,
                                "y": 181.86
                            }
                        }
                    },
                    "id": "affection",
                    "requiredToContinue": true
                },
                {
                    "pdfPageNumber": 0,
                    "type": "text-field",
                    "label": "Laquelle ?",
                    "onlyShowIf": {"id": "affection", "value": "Oui"}
                },
                {
                    "pdfPageNumber": 0,
                    "type": "text-field",
                    "label": "Quand ?",
                    "onlyShowIf": {"id": "affection", "value": "Oui"}
                },
                {
                    "pdfPageNumber": 0,
                    "type": "text-field",
                    "label": "Séquelles ?",
                    "onlyShowIf": {"id": "affection", "value": "Oui"}
                }
            ]
        },
        {
            "pageNumber": 3,
            "title": "Partie 4",
            "formElements": [
                {
                    "pdfPageNumber": 0,
                    "type": "radio",
                    "label": "Suivez-vous ou avez-vous suivi au cours des 10 dernières années, pendant plus de 21 jours, un traitement médical régulier, une surveillance médicale régulière (à l'exception de la médecine du travail, des traitements contraceptifs, du suivi de grossesse, des affections saisonnières) ?",
                    "options": {
                        "Oui": {
                            "position": {
                                "x": 98.51,
                                "y": 222.76
                            }
                        },
                        "Non": {
                            "position": {
                                "x": 89.99,
                                "y": 222.71
                            }
                        }
                    },
                    "id": "traitement_regulier",
                    "requiredToContinue": true
                },
                {
                    "pdfPageNumber": 0,
                    "type": "radio",
                    "label": "Êtes-vous ou avez-vous été, au cours des 10 dernières années, traité par radiothérapie, cobalt, chimiothérapie ou transfusion sanguine ?",
                    "options": {
                        "Oui": {
                            "position": {
                                "x": 98.61,
                                "y": 242.18
                            }
                        },
                        "Non": {
                            "position": {
                                "x": 89.94,
                                "y": 242.23
                            }
                        }
                    },
                    "id": "radiotherapie",
                    "requiredToContinue": true
                },
                {
                    "pdfPageNumber": 0,
                    "type": "text-field",
                    "label": "En cas de réponse positive, précisez pourquoi, quand, la durée, la date de guérison, les séquelles ou tout autre commentaire :",
                    "onlyShowIfAny": [{"id": "traitement_regulier", "value": "Oui"}, {"id": "radiotherapie", "value": "Oui"}]
                }
            ]
        },
        {
            "pageNumber": 4,
            "title": "Partie 5",
            "formElements": [
                {
                    "pdfPageNumber": 0,
                    "type": "radio",
                    "label": "Avez-vous, au cours des 10 dernières années, été hospitalisé ou subi une intervention chirurgicale (autre que ...",
                    "options": {
                        "Oui": {
                            "position": {
                                "x": 98.51,
                                "y": 258.16
                            }
                        },
                        "Non": {
                            "position": {
                                "x": 90.09,
                                "y": 258.27
                            }
                        }
                    },
                    "id": "hospitalise",
                    "requiredToContinue": true
                },
                {
                    "pdfPageNumber": 0,
                    "type": "text-field",
                    "label": "Pourquoi ?",
                    "onlyShowIf": {"id": "hospitalise", "value": "Oui"}
                }
            ]
        },
        {
            "pageNumber": 5,
            "title": "Partie 6",
            "formElements": [
                {
                    "pdfPageNumber": 1,
                    "type": "radio",
                    "label": "À votre connaissance, allez-vous dans les prochains mois, être hospitalisé, traité, opéré (à l'exception de la médecine du travail et du suivi systématique en prériode de grossesse) ?",
                    "options": {
                        "Oui": {
                            "position": {
                                "x": 101.94,
                                "y": 50.36
                            }
                        },
                        "Non": {
                            "position": {
                                "x": 93.16,
                                "y": 50.3
                            }
                        }
                    },
                    "id": "prochains_mois",
                    "requiredToContinue": true
                },
                {
                    "pdfPageNumber": 1,
                    "type": "text-field",
                    "label": "Pourquoi ?",
                    "onlyShowIf": {"id": "prochains_mois", "value": "Oui"}
                },
                {
                    "pdfPageNumber": 1,
                    "type": "text-field",
                    "label": "Quand ?",
                    "onlyShowIf": {"id": "prochains_mois", "value": "Oui"}
                },
                {
                    "pdfPageNumber": 1,
                    "type": "text-field",
                    "label": "Durée ?",
                    "onlyShowIf": {"id": "prochains_mois", "value": "Oui"}
                },
                {
                    "pdfPageNumber": 1,
                    "type": "text-field",
                    "label": "Nature ?",
                    "onlyShowIf": {"id": "prochains_mois", "value": "Oui"}
                }
            ]
        },
        {
            "pageNumber": 6,
            "title": "Partie 7",
            "formElements": [
                {
                    "pdfPageNumber": 1,
                    "type": "radio",
                    "label": "Avez-vous subi des tests et/ou des examens, dont le résultat se serait révélé anormal au cours des 5 dernières années ? (un électrocardiogramme, une analyse de sang, une anlyse d'urine, un test de dépistage des sérologies VHB, VHC, VIH).",
                    "options": {
                        "Oui": {
                            "position": {
                                "x": 101.83,
                                "y": 30.67
                            }
                        },
                        "Non": {
                            "position": {
                                "x": 93.05,
                                "y": 30.88
                            }
                        }
                    },
                    "id": "test_anormal",
                    "requiredToContinue": true
                },
                {
                    "pdfPageNumber": 1,
                    "type": "text-field",
                    "label": "Si oui, nature des tests et/ou examens, pourquoi, date, résultats :",
                    "onlyShowIf": {"id": "test_anormal", "value": "Oui"}
                }
            ]
        }
    ]`

    @state()
    formObject: any[] = JSON.parse(this.jsonForm)

    textFieldValueChanged(newValue: string, formElement: any) {
        if (newValue) {
            formElement.value = newValue
        }
    }

    radioButtonValueChanged(radioButtonElementId: string, newValue: string, formElement: any) {
        if (newValue) {
            this.radioButtonValuesMap.set(radioButtonElementId, newValue)
            formElement.value = formElement.options[newValue]
            this.requestUpdate()
        }
    }

    illnessValueChanged(e: CustomEvent) {
        this.illnessValue = e.detail.value === "yes";
    }

    invalidityValueChanged(e: CustomEvent) {
        this.invalidityValue = e.detail.value === "yes";
    }

    changeTabTo(tabIndex: number) {
        this.tabSelectedIndex = tabIndex

        // Call b2c api to update status.
        if (this.subscriptionId != undefined) {
            SubscriptionEndpoint.updateSubscriptionStatus(this.subscriptionId, "FORM_IN_PROGRESS")
        }

        // TODO: Update form json in the database.
    }

    nextTab() {
        this.tabSelectedIndex = this.tabSelectedIndex + 1
        if (this.subscriptionId != undefined) {
            SubscriptionEndpoint.updateSubscriptionStatus(this.subscriptionId, "FORM_IN_PROGRESS")
        }
    }

    previousTab() {
        this.tabSelectedIndex = this.tabSelectedIndex - 1
        if (this.subscriptionId != undefined) {
            SubscriptionEndpoint.updateSubscriptionStatus(this.subscriptionId, "FORM_IN_PROGRESS")
        }
    }

    async generatePdf(form: any) {
        await displayFormAsPdf(form)
    }

    render() {
        return html`
            <vaadin-tabsheet selected="${this.tabSelectedIndex}" theme="bordered">
                <vaadin-tabs
                    slot="tabs"
                    @selected-changed="${(e: CustomEvent) => {if (e.detail.value === this.formObject.length) this.generatePdf(this.formObject)}}"
                >
                    ${this.formObject.map((page: any) => {
                        return html`
                            <vaadin-tab id="page-${page.pageNumber}-tab" @click="${() => this.changeTabTo(page.pageNumber)}">
                                ${page.title}
                            </vaadin-tab>
                        `
                    })}
                    <vaadin-tab id="page-last-tab" @click="${() => this.changeTabTo(this.formObject.length)}">
                        Vérification du formulaire
                    </vaadin-tab>
                </vaadin-tabs>
                ${this.formObject.map((page: any, pageIndex: number) => {
                    return html`
                        <div tab="page-${page.pageNumber}-tab">
                            <vaadin-vertical-layout theme="spacing padding">
                                    ${page.formElements.map((formElement: any, formElementIndex: number) => {
                                        if (formElement.onlyShowIf && this.radioButtonValuesMap.get(formElement.onlyShowIf.id) != formElement.onlyShowIf.value) {
                                            return nothing
                                        } else if (formElement.onlyShowIfAny && !formElement.onlyShowIfAny.some((e: any) => this.radioButtonValuesMap.get(e.id) === e.value)) {
                                            return nothing
                                        } else {
                                            return html`
                                                ${formElement.type === "text-field" ? html`
                                                    <vaadin-text-field id="${formElement.id || nothing}"
                                                        label="${formElement.label}"
                                                        @value-changed=${(e: CustomEvent) => this.textFieldValueChanged(e.detail.value, formElement)}
                                                    ></vaadin-text-field>
                                                ` : nothing}
                                                ${formElement.type === "number-field" ? html`
                                                    <vaadin-integer-field label="${formElement.label}"></vaadin-integer-field>
                                                ` : nothing}
                                                ${formElement.type === "radio" ? html`
                                                    <vaadin-radio-group 
                                                        label="${formElement.label}"
                                                        theme="vertical" 
                                                        @value-changed=${(e: CustomEvent) => this.radioButtonValueChanged(formElement.id, e.detail.value, formElement)}
                                                    >
                                                        ${Object.keys(formElement.options).map((optionName: string) => {
                                                            return html`
                                                                <vaadin-radio-button value="${optionName}" label="${optionName}"></vaadin-radio-button>
                                                            `
                                                        })}
                                                    </vaadin-radio-group>
                                                ` : nothing}
                                                ${formElement.type === "date-picker" ? html`
                                                    <vaadin-date-picker label="${formElement.label}"></vaadin-date-picker>
                                                ` : nothing}
                                            `
                                        }
                                    })}
                                <vaadin-horizontal-layout theme="spacing">
                                    <vaadin-button theme="primary" @click="${() => this.changeTabTo(page.pageNumber + 1)}">
                                        Continuer
                                    </vaadin-button>
                                </vaadin-horizontal-layout>
                            </vaadin-vertical-layout>
                        </div>
                    `
                })}
                <div tab="page-last-tab">
                    <div id="pdf-container"></div>
                </div>
            </vaadin-tabsheet>
        `
    }

    async getForm() {
        const form = await FormEndpoint.createForm()
        if (form != undefined && form.jsonForm) {
            this.form = form
            this.formObject = JSON.parse(form.jsonForm)
        }
    }

    connectedCallback() {
        super.connectedCallback();
        this.classList.add(
            'flex',
            'flex-col',
            'gap-m',
        );
        if (this.subscriptionId != undefined) {
            SubscriptionEndpoint.updateSubscriptionStatus(this.subscriptionId, "FORM_VIEWED")
        }
        // this.getForm()
    }

}
