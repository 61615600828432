import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import * as SubscriptionEndpoint from 'Frontend/generated/SubscriptionEndpoint.js';
import { View } from '../../views/view.js';
import { Binder, field } from '@hilla/form';
import type { FormLayoutResponsiveStep } from '@vaadin/form-layout';
import '@vaadin/date-picker';
import PersonModel from 'Frontend/generated/oxynoia/documents/collection/b2b/model/PersonModel';
import type Person_1 from "Frontend/generated/oxynoia/documents/collection/b2b/model/Person.js";
import { appStore } from 'Frontend/stores/app-store.js';
import { MobxLitElement } from '@adobe/lit-mobx';

@customElement('validation-step-view')
export class ValidationStepView extends MobxLitElement {

    @property()
    subscriber!: Person_1;

    render() {
        const { subscription } = appStore

        if (subscription && subscription.subscriber) {
            return html`
                <vaadin-vertical-layout theme="spacing padding">
                    <vaadin-form-layout>
                        <vaadin-text-field
                            disabled
                            label="Prénom"
                            value="${subscription.subscriber.firstName}"
                        ></vaadin-text-field>
                        <vaadin-text-field
                            disabled
                            label="Nom"
                            value="${subscription.subscriber.lastName}"
                        ></vaadin-text-field>
                        <vaadin-text-field
                            disabled
                            label="Adresse mail"
                            value="${subscription.subscriber.email}"
                        ></vaadin-text-field>
                        <vaadin-text-field
                            disabled
                            label="N° de téléphone"
                            value="${subscription.subscriber.phoneNumber}"
                        ></vaadin-text-field>
                        <vaadin-text-field
                            disabled
                            label="Adresse"
                            value="${subscription.subscriber.address}"
                        ></vaadin-text-field>
                        <vaadin-text-field
                            disabled
                            label="Code postal"
                            value="${subscription.subscriber.zipCode}"
                        ></vaadin-text-field>
                        <vaadin-date-picker 
                            disabled
                            label="Date de naissance"
                            value="${subscription.subscriber.birthDate}"
                        ></vaadin-date-picker>
                    </vaadin-form-layout>

                    <vaadin-horizontal-layout>
                        <vaadin-button
                            theme="primary" 
                            @click="${this.validateSubscriberInformations}"
                        >
                            Valider
                        </vaadin-button>
                        <vaadin-button @click="${this.refuseToValidateInformations}">
                            Mes informations ne sont pas exactes
                        </vaadin-button>
                    </vaadin-horizontal-layout>
                </vaadin-vertical-layout>
            `;
        } else {
            return html`Pas de dossier trouvé.`
        }
    }

    connectedCallback() {
        super.connectedCallback();
        this.classList.add(
            'flex',
            'flex-col',
            'gap-m',
        );
    }

    validateSubscriberInformations() {
        appStore.setSubscriberInformationsValidated(true)
    }

    refuseToValidateInformations() {
        const { subscription } = appStore
        if (subscription != undefined && subscription.collectId != undefined) {
            SubscriptionEndpoint.refuseToValidateInformations(subscription.collectId)
        }
    }
}
