import type { Route } from '@vaadin/router';
import './views/helloworld/hello-world-view';
import './views/main-layout';
import './views/authentication/authentication-view';
import './views/big-form/big-form-view';
import './views/document/document-view';
import './views/document/document-end';
import './views/preview/preview-view';
import './views/preview-layout';
import './views/document/signed-document-error';
import './views/document/signed-document-success';

export type ViewRoute = Route & {
    title?: string;
    icon?: string;
    children?: ViewRoute[];
};

export const views: ViewRoute[] = [
    // Place routes below (more info https://hilla.dev/docs/routing)
    {
        path: 'validation',
        component: 'validation-step-view',
        icon: '',
        title: '',
    },
    {
        path: 'satisfaction',
        component: 'satisfaction-view',
        icon: '',
        title: '',
    },
    {
        path: 'form/:collectId',
        // component: 'big-form-view',
        component: 'authentication-view',
        icon: '',
        title: '',
    },
    {
        path: 'docs/:collectId',
        component: 'authentication-view',
        //component: 'document-view',
        icon: ''
    },
    {
        path: 'docs-end',
        component: 'document-end',
        icon: ''
    },
    {
        path: 'signed/success/:collectId',
        component: 'signed-document-success',
        icon: ''
    },
    {
        path: 'signed/error/:collectId',
        component: 'signed-document-error',
        icon: ''
    }
];
export const routes: ViewRoute[] = [
    {
        path: '',
        component: 'main-layout',
        children: views,
    },
    {
        path: '',
        component: 'preview-layout',
        children: [
            {
                path: 'preview',
                component: 'preview-view'
            }
        ]
    }
];
