import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { StepEventEventDispatcher } from "./step-event-dispatcher";
import RequiredDocument from "Frontend/generated/oxynoia/documents/collection/b2b/model/RequiredDocument";
import { SignatureEndpoint } from "Frontend/generated/endpoints";
import { applyTheme } from "Frontend/generated/theme";

@customElement("sign-docs-form")
class SignDocsForm extends StepEventEventDispatcher {

    @property({type: Number})
    previousStep: number = 0;

    @property({type: Number})
    nextStep: number = 0;

    @property()
    collectId: string = "";

    @property({type: Array})
    docsToSign: RequiredDocument[] = [];

    render(){

        return html`
            <h1 class="cdui-title">Documents à signer</h1>

            <div class="cdui-card" style="width: calc(100% - 60px);">
            ${this.docsToSign?.length > 0 ?
                    html`
                    <p>Vous avez des documents à signer.</p>
                    <cdui-button @click="${this.signDocument}">Signer mes documents</cdui-button>
                                    `
                    : html`
                <p>Vous n'avez aucun document à signer.</p>
                `
                }
            </div>

            <div class="cdui-pagination">
                <div class="cdui-pagination-link" @click=${() =>  this.goToStep(this.previousStep)}>
                    <cdui-button class="round">ᐸ</cdui-button>
                    <span>Précédent</span>
                </div>
                <cdui-button @click=${() => this.goToStep(this.nextStep)}>Soumettre en l'état</cdui-button>
                <div class="cdui-pagination-link" @click=${() => this.goToStep(this.nextStep)}>
                    <span>Suivant</span>
                    <cdui-button class="round">ᐳ</cdui-button>
                </div>
            </div>
        `;
    }

    private signDocument(e: CustomEvent) {
        SignatureEndpoint.signDocuments(this.collectId, this.docsToSign).then(uri => {
            if (uri !== undefined && uri?.length > 0) {
                window.onbeforeunload = function () { };
                window.location.href = uri;
            }
        });
    }

    connectedCallback(): void {
        super.connectedCallback();
        applyTheme(this.renderRoot);
    }

}