import '@vaadin/app-layout';
import { AppLayout } from '@vaadin/app-layout';
import '@vaadin/app-layout/vaadin-drawer-toggle';
import '@vaadin/avatar';
import '@vaadin/icon';
import '@vaadin/menu-bar';
import '@vaadin/scroller';
import '@vaadin/side-nav';
import '@vaadin/tabs';
import '@vaadin/tabs/vaadin-tab';
import '@vaadin/vaadin-lumo-styles/vaadin-iconset';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { router } from '../index.js';
import { views } from '../routes.js';
import { appStore } from '../stores/app-store.js';
import { Layout } from './view.js';
import { applyTheme } from 'Frontend/generated/theme.js';
import { BeforeEnterObserver, RouterLocation } from '@vaadin/router';
import { TenantStyleEndpoint } from 'Frontend/generated/endpoints.js';

interface RouteInfo {
    path: string;
    title: string;
    icon: string;
}

@customElement('main-layout')
export class MainLayout extends Layout implements BeforeEnterObserver {

    private _tenantId!: string;

    onBeforeEnter(location: RouterLocation) {
        if (location.params.tenantId) {
            this._tenantId = location.params.tenantId as string;
        }
        // this.collectId = location.params.collectId as string;
        // if(location.search){
        //     let searchParams = new URLSearchParams(location.search);
        //     if(searchParams.has("from")){
        //         this._from = searchParams.get("from");
        //     }
        // }

    }

    render() {
        return html`
      <vaadin-app-layout class="h-full w-full">
        <vaadin-vertical-layout class="main-content">
            <slot><!-- The router puts views here -->
                /icons/icon.png
            </slot>
            
        </vaadin-vertical-layout>
      </vaadin-app-layout>
    `;
    }

    connectedCallback() {
        super.connectedCallback();
        applyTheme(this.renderRoot);
        if (this._tenantId) {
            TenantStyleEndpoint.getStyle(this._tenantId).then(data => {
                if(data){
                    if(data.backgroundColor){
                        this.style.setProperty("--blue-gradient", data.backgroundColor);
                    }
                }
            });
            //change style here
            // let customStyle =
            // this.style.setProperty("--blue-gradient", "black");
        }

        this.classList.add('block', 'h-full');
        this.reaction(
            () => appStore.location,
            () => {
                AppLayout.dispatchCloseOverlayDrawerEvent();
            }
        );
    }

    private getMenuRoutes(): RouteInfo[] {
        return views.filter((route) => route.title) as RouteInfo[];
    }
}
